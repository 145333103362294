import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { createHead, VueHeadMixin } from '@vueuse/head'

const head = createHead()

createApp(App)
.use(store)
.use(router)
.mixin(VueHeadMixin)
.use(head)
.mount('#app')