<template>
    <main>
        <div class="container">   
            <institution title='Суши бары' type='sushi-bary' />

            <institution title='Рестораны' type='restorany' />

            <institution title='Кафе' type='kafe' />

            <institution title='Бары' type='bary' />

            <institution title='Караоке' type='karaoke' />

            <institution title='Кафетерии' type='kafeteriy' />

            <institution title='Кальянные' type='kalyannye' />

            <institution title='Столовые' type='stolovye' />

            <institution title='Буфеты' type='bufety' />

            <institution title='Пиццерии' type='piccerii' />

            <institution title='Пабы' type='paby' />

            <institution title='Кофейные' type='kofeynye' />

            <mobile />
        </div>
    </main>
</template>

<script>
import { useHead } from '@vueuse/head'
import institution from '@/components/home/app-institution.vue'
import mobile from '@/components/app-mobile.vue'
export default {
    components: {
        institution,
        mobile
    },
    setup(){
        useHead({
            title: 'Musatos',
            link: [{
                rel: 'stylesheet',
                href: '/static/css/main.css'
            }],
        })
    },
}
</script>
