<template>
    <section class="mobile">
        <img src="/static/img/mobile.png" alt="mobile">

        <h6>Используйте приложение, чтобы найти лучшие рестораны в вашем городе</h6>
        
        <div class="mobile__links">
            <a href="#" target="_blank">
                <img src="/static/img/app__store.svg" alt="App Store">
            </a>

            <a href="#">
                <img src="/static/img/google_play.svg" alt="Google Play">
            </a>
        </div>
    </section>
</template>