<template>
    <section class="section" v-if="show">
        <h2>{{ title }}</h2>
        <div class="establishments">
        <router-link v-for="item in establishments" :key="item.slug" :to="'/'+item.type+'/'+item.slug" class="institution">
            <figure v-if="item.thumb === null || item.thumb === ''"></figure>
            <img v-else v-bind:src="item.thumb.home" v-bind:alt="item.name">
            <h5 v-html="item.name"></h5>
            <!-- <p v-html="item.name"></p> -->
        </router-link>
    </div>         
    </section>
</template>

<script>
    export default {
        props: ['title', 'type'],
        data () {
            return {
                show: false,
                establishments: []
            }
        },
        methods: {
        },
        beforeMount () {
            let requestOptions = {
                method: 'GET',
                headers: {
                    'api-key': process.env.VUE_APP_COCKPIT_API_TOKEN,
                    'Content-Type': 'application/json'
                },
                redirect: 'follow'
            }
            fetch(process.env.VUE_APP_COCKPIT_ENDPOINT + 'api/musatos/archive?limit=10&filter={type:"' + this.type + '"}', requestOptions)
            .then(response => response.json())
            .then(result => {
                this.establishments = result
                if(result.length !=0 ){
                    this.show = true
                }
            })
        },
        mounted () {
            // console.log(this.establishments)
        }
    }
</script>