<template>
    <div class="header__navLinks">
        <a href="/">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M17 10.19L12 5.69L7 10.19V18H9H11V20H5V12H2L12 3L22 12H19V20H13V18H15H17V10.19Z" fill="black"/>
                <path d="M11 18V20H13V18H11Z" fill="black"/>
            </svg>
            Главная
        </a>

        <a href="#">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M16.9497 4.05025C15.637 2.7375 13.8565 2 12 2C10.1435 2 8.36301 2.7375 7.05025 4.05025C5.7375 5.36301 5 7.14348 5 9C5 14.25 12 22 12 22C12 22 19 14.25 19 9C19 7.14348 18.2625 5.36301 16.9497 4.05025ZM8.46447 5.46447C9.40215 4.52678 10.6739 4 12 4C13.3261 4 14.5979 4.52678 15.5355 5.46447C16.4732 6.40215 17 7.67392 17 9C17 10 17 12 12 18.71C7 12 7 10 7 9C7 7.67392 7.52678 6.40215 8.46447 5.46447Z" fill="black"/>
            </svg>
            Заведения поблизости
        </a>

        <a href="#">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M20 19V5H4V19H20ZM4 3H20C21.11 3 22 3.89 22 5V19C22 20.11 21.11 21 20 21H4C2.89 21 2 20.11 2 19V5C2 3.89 2.89 3 4 3ZM6 7H10V13H6V7Z" fill="black"/>
            </svg>

            Новости заведений
        </a>

        <a href="#">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M9.50008 21.58C9.54008 21.82 9.75008 22 10.0001 22H14.0001C14.2501 22 14.4601 21.82 14.5001 21.58L14.8701 18.93C15.5001 18.68 16.0401 18.34 16.5601 17.95L19.0501 18.95C19.2701 19.04 19.5401 18.95 19.6601 18.73L21.6601 15.27C21.7901 15.05 21.7301 14.78 21.5401 14.63L19.4301 13L19.5001 12L19.4301 11L21.5401 9.37C21.7301 9.22 21.7901 8.95 21.6601 8.73L19.6601 5.27C19.5401 5.05 19.2701 4.96 19.0501 5.05L16.5601 6.05C16.0401 5.66 15.5001 5.32 14.8701 5.07L14.5001 2.42C14.4601 2.18 14.2501 2 14.0001 2H10.0001C9.75008 2 9.54008 2.18 9.50008 2.42L9.13008 5.07C8.50008 5.32 7.96008 5.66 7.44008 6.05L4.95008 5.05C4.73008 4.96 4.46008 5.05 4.34008 5.27L2.34008 8.73C2.21008 8.95 2.27008 9.22 2.46008 9.37L4.57008 11L4.50008 12L4.57008 12.97L2.46008 14.63C2.27008 14.78 2.21008 15.05 2.34008 15.27L4.34008 18.73C4.46008 18.95 4.73008 19.03 4.95008 18.95L7.44008 17.94C7.96008 18.34 8.50008 18.68 9.13008 18.93L9.50008 21.58ZM10.8801 6.61L11.2501 4H12.7501L13.1201 6.62C14.3201 6.86 15.3801 7.5 16.1501 8.39L18.5601 7.35L19.3101 8.65L17.2001 10.2C17.6001 11.37 17.6001 12.64 17.2001 13.81L19.3201 15.36L18.5701 16.66L16.1401 15.62C15.3701 16.5 14.3201 17.14 13.1301 17.39L12.7601 20H11.2401L10.8701 17.38C9.68008 17.14 8.63008 16.5 7.86008 15.62L5.43008 16.66L4.68008 15.36L6.80008 13.8C6.40008 12.64 6.40008 11.37 6.80008 10.2L4.69008 8.65L5.44008 7.35L7.85008 8.39C8.62008 7.5 9.68008 6.86 10.8801 6.61Z" fill="black"/>
            </svg>
            Настроки
        </a>
    </div>
</template>