<template>
    <div class="header__navLinks">
        <a href="/">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M17 10.19L12 5.69L7 10.19V18H9H11V20H5V12H2L12 3L22 12H19V20H13V18H15H17V10.19Z" fill="black"/>
                <path d="M11 18V20H13V18H11Z" fill="black"/>
            </svg>
            Главная
        </a>

        <a href="/kabinet">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M12 4C13.0609 4 14.0783 4.42143 14.8284 5.17157C15.5786 5.92172 16 6.93913 16 8C16 9.06087 15.5786 10.0783 14.8284 10.8284C14.0783 11.5786 13.0609 12 12 12C10.9391 12 9.92172 11.5786 9.17157 10.8284C8.42143 10.0783 8 9.06087 8 8C8 6.93913 8.42143 5.92172 9.17157 5.17157C9.92172 4.42143 10.9391 4 12 4ZM12 6C11.4696 6 10.9609 6.21071 10.5858 6.58579C10.2107 6.96086 10 7.46957 10 8C10 8.53043 10.2107 9.03914 10.5858 9.41421C10.9609 9.78929 11.4696 10 12 10C12.5304 10 13.0391 9.78929 13.4142 9.41421C13.7893 9.03914 14 8.53043 14 8C14 7.46957 13.7893 6.96086 13.4142 6.58579C13.0391 6.21071 12.5304 6 12 6ZM12 13C14.67 13 20 14.33 20 17V20H4V17C4 14.33 9.33 13 12 13ZM12 14.9C9.03 14.9 5.9 16.36 5.9 17V18.1H18.1V17C18.1 16.36 14.97 14.9 12 14.9Z" fill="black"/>
            </svg>
            Личный кабинет
        </a>

        <a v-if="currentUser.role === 'user'" href="/izbrannoye">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M16.9999 5V18L11.9999 15.82L6.99994 18V5H16.9999ZM18.4142 3.58579C18.0391 3.21071 17.5304 3 16.9999 3H6.99994C5.89994 3 4.99994 3.89 4.99994 5V21L11.9999 18L18.9999 21V5C18.9999 4.46957 18.7892 3.96086 18.4142 3.58579Z" fill="black"/>
            </svg>
            Избранное
        </a>

        <a v-if="currentUser.role === 'user'" href="#">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M2.20001 16.0592L3.88001 11.9992L2.20001 7.93922L6.26001 6.25922L7.94001 2.19922L12 3.87922L16.06 2.19922L17.74 6.25922L21.8 7.93922L20.12 11.9992L21.8 16.0592L17.74 17.7392L16.06 21.7992L12 20.1192L7.94001 21.7992L6.26001 17.7392L2.20001 16.0592ZM4.81001 8.99922L6.05001 11.9992L4.81001 14.9992L7.79001 16.2092L9.00001 19.1892L12 17.9492L15 19.1892L16.21 16.2092L19.19 14.9992L17.95 11.9992L19.19 8.99922L16.21 7.78922L15 4.80922L12 6.04922L9.00001 4.80922L7.79001 7.78922L4.81001 8.99922Z" fill="black"/>
            </svg>
            Акции
        </a>

        <a v-if="currentUser.role === 'user'" href="#">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M16.9497 4.05025C15.637 2.7375 13.8565 2 12 2C10.1435 2 8.36301 2.7375 7.05025 4.05025C5.7375 5.36301 5 7.14348 5 9C5 14.25 12 22 12 22C12 22 19 14.25 19 9C19 7.14348 18.2625 5.36301 16.9497 4.05025ZM8.46447 5.46447C9.40215 4.52678 10.6739 4 12 4C13.3261 4 14.5979 4.52678 15.5355 5.46447C16.4732 6.40215 17 7.67392 17 9C17 10 17 12 12 18.71C7 12 7 10 7 9C7 7.67392 7.52678 6.40215 8.46447 5.46447Z" fill="black"/>
            </svg>
            Заведения поблизости
        </a>

        <a v-if="currentUser.role === 'user'" href="#">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M20 19V5H4V19H20ZM4 3H20C21.11 3 22 3.89 22 5V19C22 20.11 21.11 21 20 21H4C2.89 21 2 20.11 2 19V5C2 3.89 2.89 3 4 3ZM6 7H10V13H6V7Z" fill="black"/>
            </svg>
            Новости заведений
        </a>

        <a v-if="currentUser.role === 'user'" href="#">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M19 3H18V1H16V3H8V1H6V3H5C3.89 3 3 3.9 3 5V19C3 20.11 3.9 21 5 21H19C20.11 21 21 20.11 21 19V5C21 3.9 20.11 3 19 3ZM19 19H5V9H19V19ZM19 7H5V5H19V7Z" fill="black"/>
            </svg>
            Мои заказы
        </a>

        <a v-if="currentUser.role === 'user'" href="#">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M9.50008 21.58C9.54008 21.82 9.75008 22 10.0001 22H14.0001C14.2501 22 14.4601 21.82 14.5001 21.58L14.8701 18.93C15.5001 18.68 16.0401 18.34 16.5601 17.95L19.0501 18.95C19.2701 19.04 19.5401 18.95 19.6601 18.73L21.6601 15.27C21.7901 15.05 21.7301 14.78 21.5401 14.63L19.4301 13L19.5001 12L19.4301 11L21.5401 9.37C21.7301 9.22 21.7901 8.95 21.6601 8.73L19.6601 5.27C19.5401 5.05 19.2701 4.96 19.0501 5.05L16.5601 6.05C16.0401 5.66 15.5001 5.32 14.8701 5.07L14.5001 2.42C14.4601 2.18 14.2501 2 14.0001 2H10.0001C9.75008 2 9.54008 2.18 9.50008 2.42L9.13008 5.07C8.50008 5.32 7.96008 5.66 7.44008 6.05L4.95008 5.05C4.73008 4.96 4.46008 5.05 4.34008 5.27L2.34008 8.73C2.21008 8.95 2.27008 9.22 2.46008 9.37L4.57008 11L4.50008 12L4.57008 12.97L2.46008 14.63C2.27008 14.78 2.21008 15.05 2.34008 15.27L4.34008 18.73C4.46008 18.95 4.73008 19.03 4.95008 18.95L7.44008 17.94C7.96008 18.34 8.50008 18.68 9.13008 18.93L9.50008 21.58ZM10.8801 6.61L11.2501 4H12.7501L13.1201 6.62C14.3201 6.86 15.3801 7.5 16.1501 8.39L18.5601 7.35L19.3101 8.65L17.2001 10.2C17.6001 11.37 17.6001 12.64 17.2001 13.81L19.3201 15.36L18.5701 16.66L16.1401 15.62C15.3701 16.5 14.3201 17.14 13.1301 17.39L12.7601 20H11.2401L10.8701 17.38C9.68008 17.14 8.63008 16.5 7.86008 15.62L5.43008 16.66L4.68008 15.36L6.80008 13.8C6.40008 12.64 6.40008 11.37 6.80008 10.2L4.69008 8.65L5.44008 7.35L7.85008 8.39C8.62008 7.5 9.68008 6.86 10.8801 6.61Z" fill="black"/>
            </svg>
            Мои резервы
        </a>

        <a v-if="currentUser.role === 'admin'" href="/moderator">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M4 8V14V17V19H20V17V14V8H17V10H15V8H9V10H7V8H4ZM15 4H9V6H15V4ZM17 6H20C21.11 6 22 6.89 22 8V19C22 20.11 21.11 21 20 21H4C2.89 21 2 20.11 2 19V8C2 6.89 2.89 6 4 6H7V4C7 2.89 7.89 2 9 2H15C16.11 2 17 2.89 17 4V6Z" fill="black"/>
            </svg>
            Модератор
        </a>

        <a v-if="currentUser.role === 'seller'" href="/biznes">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M4 8V14V17V19H20V17V14V8H17V10H15V8H9V10H7V8H4ZM15 4H9V6H15V4ZM17 6H20C21.11 6 22 6.89 22 8V19C22 20.11 21.11 21 20 21H4C2.89 21 2 20.11 2 19V8C2 6.89 2.89 6 4 6H7V4C7 2.89 7.89 2 9 2H15C16.11 2 17 2.89 17 4V6Z" fill="black"/>
            </svg>
            Бизнес
        </a>

        <a href="#">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M9.50008 21.58C9.54008 21.82 9.75008 22 10.0001 22H14.0001C14.2501 22 14.4601 21.82 14.5001 21.58L14.8701 18.93C15.5001 18.68 16.0401 18.34 16.5601 17.95L19.0501 18.95C19.2701 19.04 19.5401 18.95 19.6601 18.73L21.6601 15.27C21.7901 15.05 21.7301 14.78 21.5401 14.63L19.4301 13L19.5001 12L19.4301 11L21.5401 9.37C21.7301 9.22 21.7901 8.95 21.6601 8.73L19.6601 5.27C19.5401 5.05 19.2701 4.96 19.0501 5.05L16.5601 6.05C16.0401 5.66 15.5001 5.32 14.8701 5.07L14.5001 2.42C14.4601 2.18 14.2501 2 14.0001 2H10.0001C9.75008 2 9.54008 2.18 9.50008 2.42L9.13008 5.07C8.50008 5.32 7.96008 5.66 7.44008 6.05L4.95008 5.05C4.73008 4.96 4.46008 5.05 4.34008 5.27L2.34008 8.73C2.21008 8.95 2.27008 9.22 2.46008 9.37L4.57008 11L4.50008 12L4.57008 12.97L2.46008 14.63C2.27008 14.78 2.21008 15.05 2.34008 15.27L4.34008 18.73C4.46008 18.95 4.73008 19.03 4.95008 18.95L7.44008 17.94C7.96008 18.34 8.50008 18.68 9.13008 18.93L9.50008 21.58ZM10.8801 6.61L11.2501 4H12.7501L13.1201 6.62C14.3201 6.86 15.3801 7.5 16.1501 8.39L18.5601 7.35L19.3101 8.65L17.2001 10.2C17.6001 11.37 17.6001 12.64 17.2001 13.81L19.3201 15.36L18.5701 16.66L16.1401 15.62C15.3701 16.5 14.3201 17.14 13.1301 17.39L12.7601 20H11.2401L10.8701 17.38C9.68008 17.14 8.63008 16.5 7.86008 15.62L5.43008 16.66L4.68008 15.36L6.80008 13.8C6.40008 12.64 6.40008 11.37 6.80008 10.2L4.69008 8.65L5.44008 7.35L7.85008 8.39C8.62008 7.5 9.68008 6.86 10.8801 6.61Z" fill="black"/>
            </svg>
            Настроки
        </a>

        <a href="/logout" class="header__logout" @click.prevent="logOut">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M14.08 15.59L16.67 13H7V11H16.67L14.08 8.41L15.5 7L20.5 12L15.5 17L14.08 15.59ZM19 3C19.5304 3 20.0391 3.21071 20.4142 3.58579C20.7893 3.96086 21 4.46957 21 5V9.67L19 7.67V5H5V19H19V16.33L21 14.33V19C21 19.5304 20.7893 20.0391 20.4142 20.4142C20.0391 20.7893 19.5304 21 19 21H5C3.89 21 3 20.1 3 19V5C3 3.89 3.89 3 5 3H19Z" fill="black"/>
            </svg>
            Выйти
        </a>
    </div>
</template>

<script>
export default {
    computed: {
        currentUser() {
            return this.$store.state.auth.user;
        }
    },
    methods: {
        logOut() {
            this.$router.push('/')
            this.$store.dispatch('auth/logout')
            // this.$router.push('/')
            // window.location.reload('/')
        }
    }
};
</script>